import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Smartphones, magnificent little things. But there's only 4 kinds. Draw a
responsive stackchart of their marketshare.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactviz.holiday/datasets/statistic_id266572_us-smartphone-market-share-2012-2018-by-month.xlsx"
      }}>{`Dataset`}</a></p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/lbHy8SF39k8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/0xj8q4k2pp?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`We've built stackcharts before, on the
`}<a parentName="p" {...{
        "href": "https://reactviz.holiday/christmas-gifts/"
      }}>{`What do Americans want for Christmas`}</a>{`
day. That means we can focus on teh responsive part today.`}</p>
    <p>{`Although I still had to build the full stack chart from scratch and my
jetlagged brain struggled. Sorry viewers. You might want to skip the first
several minutes of the stream 😅`}</p>
    <h2 {...{
      "id": "how-to-make-a-responsive-chart-with-react-and-d3"
    }}>{`How to make a responsive chart with React and D3`}</h2>
    <p>{`There's two parts to making responsive charts and data visualizations:`}</p>
    <ol>
      <li parentName="ol">{`Build your chart so it conforms to a width and height`}</li>
      <li parentName="ol">{`Use CSS to resize your SVG based on viewport size`}</li>
      <li parentName="ol">{`React to window size changes`}</li>
      <li parentName="ol">{`Read SVG size`}</li>
      <li parentName="ol">{`Pass it into your chart`}</li>
    </ol>
    <p>{`We'll go from the outside-in.`}</p>
    <h3 {...{
      "id": "dynamically-sized-svg"
    }}>{`Dynamically sized SVG`}</h3>
    <p>{`There's a few ways you can render your SVG so it resizes based on available
space. Flexbox, css grid, old school CSS tricks.`}</p>
    <p>{`The easist is a `}<inlineCode parentName="p">{`100%`}</inlineCode>{` width.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<svg width="100%" height="400" ref={this.svgRef}>
  {data && (
    <ResponsiveStackChart
      data={data}
      keys={["android", "ios", "blackberry", "microsoft"]}
      width={width}
      height={height}
    />
  )}
</svg>
`}</code></pre>
    <p>{`Our SVG always occupies the full width of its parent div - the whole page in
our case. It contains a `}<inlineCode parentName="p">{`<ResponsiveStackChart>`}</inlineCode>{` that accepts width, height,
and data.`}</p>
    <p>{`Those four come from state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const { data, width, height } = this.state
`}</code></pre>
    <p>{`You could track different widths for different charts, do some layouting,
things like that. We don't need those complications because this is a small
example.`}</p>
    <h3 {...{
      "id": "listen-to-window-size-changes"
    }}>{`Listen to window size changes`}</h3>
    <p>{`Now that we have a dynamic SVG, we have to read its size every time the window
size changes. That happens when users resize their browser (never), or when
they turn their phone (sometimes).`}</p>
    <p>{`In reality this part almost never happens. People rarely resize their browsers
and only turn their phones if you give them a reason to. But it's a nice touch
when we're talking about responsive :)`}</p>
    <p>{`We add a listener to the `}<inlineCode parentName="p">{`resize`}</inlineCode>{` window event in `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` and
remove it in `}<inlineCode parentName="p">{`componentWillUnmount`}</inlineCode>{`. Both in the main App componenet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`componentDidMount() {
    // data loading

    this.measureSVG();
    window.addEventListener("resize", this.measureSVG);
}

componentWillUnmount() {
    window.removeEventListener("resize", this.measureSVG);
}
`}</code></pre>
    <p><inlineCode parentName="p">{`measureSVG`}</inlineCode>{` is where the next bit happens.`}</p>
    <h3 {...{
      "id": "measure-svg-element-size"
    }}>{`Measure SVG element size`}</h3>
    <p>{`A useful DOM method engineers often forget exists is `}<inlineCode parentName="p">{`getBoundingClientRect`}</inlineCode>{`.
Tells you the exact size of a DOM node. Great for stuff like this 👌`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`measureSVG = () => {
  const { width, height } = this.svgRef.current.getBoundingClientRect()

  this.setState({
    width,
    height,
  })
}
`}</code></pre>
    <p>{`Take the bounding client rect of our SVG element, read out its width and
height, save it to state. This triggers a re-render of our app, passes new
sizing props into the chart, and the chart resizes itself.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/12NUbkX6p4xOO4/giphy.gif",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "a-chart-that-listens-to-its-width-and-height"
    }}>{`A chart that listens to its width and height`}</h2>
    <p>{`Now that we've got dynamic always accurate width and height, we have to listen
to them.`}</p>
    <p>{`Best way to do that is with D3 scales that you keep up to date. We use the dynamic full integration approach from the React For DataVisualization course.`}</p>
    <p>{`That means:`}</p>
    <ol>
      <li parentName="ol">{`Scales go into state`}</li>
      <li parentName="ol">{`Scales update their domain and range in `}<inlineCode parentName="li">{`getDerivedStateFromProps`}</inlineCode></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ResponsiveStackChart extends React.Component {
  state = {
    xScale: d3
      .scaleBand()
      .domain(this.props.data.map(d => d.date))
      .range([0, 600]),
    yScale: d3.scaleLinear().range([0, 600])
  };
  stack = d3.stack().keys(this.props.keys);
  color = chroma.brewer.Paired;

  static getDerivedStateFromProps(props, state) {
    let { xScale, yScale } = state;

    xScale.domain(props.data.map(d => d.date)).range([0, props.width]);
    yScale.range([0, props.height - 50]);

    return {
      ...state,
      xScale,
      yScale
    };
  }
`}</code></pre>
    <p>{`We define default state for our `}<inlineCode parentName="p">{`xScale`}</inlineCode>{` and `}<inlineCode parentName="p">{`yScale`}</inlineCode>{`. Both assume the chart is
going to be 600x600 pixels. xScale has a domain with every identifier in our
dataset, the month/year, and yScale will get its domain in the render function.
I'll explain why.`}</p>
    <p><inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{` runs every time our component updates for any
reason. A good place to update our scales so they fit any new into from props.`}</p>
    <p>{`We redefine their ranges to match the `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` props. If we are
careful to always rely on scales to position and size elements on our chart,
the chart will automatically resize.`}</p>
    <h3 {...{
      "id": "the-stack-layout"
    }}>{`The stack layout`}</h3>
    <p>{`To avoid calculating the stack layout multiple times, we do it in the render
method. Need its data for rendering and for the `}<inlineCode parentName="p">{`yScale`}</inlineCode>{` domain.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`render() {
    const { data, height } = this.props,
      { yScale, xScale } = this.state;
    const stack = this.stack(data);

    yScale.domain([0, d3.max(stack[stack.length - 1].map(d => d[1]))]);
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`stack`}</inlineCode>{` generator returns an array of arrays. At the top level we have an
array for every `}<inlineCode parentName="p">{`key`}</inlineCode>{` in our dataset. Inside is an array of tuples for each
datapoint. The touples hold a `}<inlineCode parentName="p">{`min`}</inlineCode>{` and `}<inlineCode parentName="p">{`max`}</inlineCode>{` value that tells us where a
datapoint starts and ends.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`d3.max`}</inlineCode>{` to find the highest value in the stack data and feed it into
yScale's domain so it can proportionally size everything when we render.`}</p>
    <p>{`👌`}</p>
    <h2 {...{
      "id": "an-axis-with-dynamic-number-of-tricks"
    }}>{`An axis with dynamic number of tricks`}</h2>
    <p>{`The last step is making our axis look good at every size. We have to make sure
ticks don't overlap and their number adapts to available space.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const BottomAxis = d3blackbox((anchor, props) => {
  const scale = props.scale,
    tickWidth = 60,
    width = scale.range()[1],
    tickN = Math.floor(width / tickWidth),
    keepEveryNth = Math.floor(scale.domain().length / tickN)

  scale.domain(scale.domain().filter((_, i) => i % keepEveryNth === 0))

  const timeFormat = d3.timeFormat("%b %Y")
  const axis = d3.axisBottom().scale(props.scale).tickFormat(timeFormat)
  d3.select(anchor.current).call(axis)
})
`}</code></pre>
    <p>{`This is quite mathsy. The idea works like this:`}</p>
    <ol>
      <li parentName="ol">{`Decide how much room you want for each tick - `}<inlineCode parentName="li">{`tickWidth`}</inlineCode></li>
      <li parentName="ol">{`Read the width from scale.range - `}<inlineCode parentName="li">{`width`}</inlineCode></li>
      <li parentName="ol">{`Use division to decide how many ticks fit - `}<inlineCode parentName="li">{`tickN`}</inlineCode></li>
      <li parentName="ol">{`Some more division to decide every Nth tick you can keep - `}<inlineCode parentName="li">{`keepEveryNth`}</inlineCode></li>
    </ol>
    <p>{`Then we filter the scale's domain and keep only every `}<inlineCode parentName="p">{`keepEveryNth`}</inlineCode>{` element.`}</p>
    <p>{`Only reason we need this is because we're using a band scale, which is an
ordinal scale. Means D3 can't easily interpolate datapoints and figure these
things out on its own.`}</p>
    <p>{`The result is a perfectly responsive chart 👇`}</p>
    <blockquote className="twitter-tweet" data-lang="en">
  <p lang="en" dir="ltr">
    A responsive{" "}
    <a href="https://twitter.com/hashtag/react?src=hash&amp;ref_src=twsrc%5Etfw">
      #react
    </a>{" "}
    and{" "}
    <a href="https://twitter.com/hashtag/d3?src=hash&amp;ref_src=twsrc%5Etfw">
      #d3
    </a>{" "}
    stackchart.{" "}
    <a href="https://twitter.com/hashtag/ReactVizHoliday?src=hash&amp;ref_src=twsrc%5Etfw">
      #ReactVizHoliday
    </a>{" "}
    10
    <br />
    <br />
    👉 <a href="https://t.co/8a8r5ifhyz">https://t.co/8a8r5ifhyz</a> <a href="https://t.co/kMWgUAZB4J">pic.twitter.com/kMWgUAZB4J</a>
  </p>
  &mdash; Swizec Teller (@Swizec) <a href="https://twitter.com/Swizec/status/1074202928221700097?ref_src=twsrc%5Etfw">December 16, 2018</a>
    </blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      